import React from 'react';
import { FilterCard, PriceTierCompliance } from '@/components';
import { Card, CardTitle, Container } from 'reactstrap';
import Button from '@/components/LoadingButton';
import { useFormikContext } from 'formik';
import { useUser } from '@/hooks';
import classNames from 'classnames';
import { formatCurrencyForDisplay } from '@/utils';

const PurchaseBulkLeads: React.FC<{
  toggle: () => void;
}> = ({ toggle }) => {
  const { isValid, errors } = useFormikContext();
  const { user } = useUser();
  return (
    <>
      <Container className="mt-3">
        <PriceTierCompliance type="bulk" isNew={true} />
        <FilterCard type="bulk" />
        <Card>
          <CardTitle
            tag="h4"
            className="d-flex justify-content-between align-items-center m-3"
          >
            Total Account Credit:
            <div className="text-danger"> {errors.agentCredit}</div>
            <div
              className={classNames({
                'text-success': user?.totalCredit / 100 > 0,
                'text-danger': user?.totalCredit / 100 <= 0,
              })}
            >
              {formatCurrencyForDisplay(user?.totalCredit)}
            </div>
          </CardTitle>
        </Card>
        <Button
          id="bulk-purchase-open-modal-button"
          color="primary"
          className="float-right mt-3"
          onClick={toggle}
          disabled={!isValid}
        >
          Review Order
        </Button>
      </Container>
    </>
  );
};

export default PurchaseBulkLeads;
