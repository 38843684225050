/* eslint-disable unicorn/consistent-function-scoping */
/* global chrome */
import React, { useCallback, useEffect } from 'react';
import { StorageHelper } from '@aws-amplify/core';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { Alert, Button, Container } from 'reactstrap';
import Amplify, { Auth } from 'aws-amplify';
import { Footer, Nav, ErrorBoundary } from '@/components';
import { DenyApproved, DenyBeta, DenySonic } from '@/components/DenyRedirects';
import {
  CampaignsNetworkProvider,
  LoadingBarProvider,
  NotificationProvider,
} from '@/context';
import { useUser } from '@/hooks';
import {
  HomePage,
  LeadsPage,
  TransfersPage,
  SupportPage,
  MyAgents,
  CampaignPage,
  ProfilePage,
  VendorsPage,
  CampaignDetails,
  VendorDetails,
  ReportsPage,
  VoyagerPage,
  BulkPurchasePage,
  AnalyticsPage,
} from '@/areas';
import { Vars } from '@/utils';
import { useApolloClient } from '@apollo/react-hooks';
import { FaDoorOpen } from 'react-icons/fa';
import classNames from 'classnames';
import TextdripAuth from './areas/Profile/components/Services/Components/TextdripAuth';
import GoHighLevelAuth from './areas/Profile/components/Services/Components/GoHighLevelAuth';
import { TermsAcceptanceModal } from './modals';

Amplify.configure(Vars.awsmobile);

const isSonic = window.location.href.includes('sonicleads');
const isLeadtopia = window.location.href.includes('leadtopia');
const isBeta = window.location.href.includes('beta');
const isAmericafirst = window.location.href.includes('americafirstleads');

const AppWrapper: React.FunctionComponent = () => {
  const { user } = useUser();
  const location = useLocation();
  const history = useHistory();

  const { cache } = useApolloClient();

  const syncExtension = async (): Promise<void> => {
    const { extensionId } = Vars;
    const localStorage = new StorageHelper().getStorage();
    try {
      await chrome.runtime.sendMessage(extensionId, {
        type: 'MP_AUTH',
        data: localStorage,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && !user.agentApproved) history.push('/profile/me');
    const syncE = async () => {
      const data = await syncExtension();
    };
    syncE().catch(console.error);
  }, [history, user]);

  const generateNullComponent = useCallback(
    (Component: React.ComponentClass<any> | React.FunctionComponent<any>) =>
      user?.agentApproved ? Component : () => null,
    [user, user?.agentApproved]
  );

  if (isBeta && user && !user.flags.allowBeta) {
    return <DenyBeta />;
  }
  if (user && !user.agentApproved && !user.flags.allowSelfEdit) {
    return <DenyApproved isSonic={isSonic} />;
  }

  if (
    user &&
    ((isSonic && user?.corpID !== 'sonic') ||
      (!isSonic && user?.corpID === 'sonic'))
    // (isLeadtopia && user?.corpID !== 'leadtopia') ||
    // (!isLeadtopia && user?.corpID === 'leadtopia') // ||
    // (isAmericafirst && user?.corpID !== 'americafirst') ||
    // (!isAmericafirst && user?.corpID === 'americafirst')
  ) {
    return <DenySonic isSonic={isSonic} />;
  }

  return (
    <>
      <TermsAcceptanceModal />
      {user?.agentApproved && location.pathname === '/live-transfers' ? null : (
        <Nav />
      )}
      {!user?.agentApproved && (
        <Alert
          color="info"
          className="d-flex align-items-center justify-content-between rounded-0"
        >
          Hello! Your account must be approved before you can use marketplace
          services.{' '}
          {isLeadtopia || isAmericafirst ? 'An admin' : 'Your region admin'} has
          been notified, and there is no further action needed.
          <Button
            className="float-right d-flex align-items-center"
            size="sm"
            color="secondary"
            onClick={() => {
              Auth.signOut({ global: true });
              localStorage.clear();
              cache.reset();
            }}
          >
            <FaDoorOpen className="mr-1" />
            Sign out
          </Button>
        </Alert>
      )}
      <ErrorBoundary allowDismiss>
        <CampaignsNetworkProvider>
          <LoadingBarProvider>
            <div
              className="sonic-container"
              style={{
                paddingTop:
                  location.pathname.includes('/voyager') ||
                  (location.pathname === '/' && !isLeadtopia) ||
                  (location.pathname === '/' && !isAmericafirst) ||
                  location.pathname === '/live-transfers' ||
                  location.pathname === '/analytics'
                    ? 0
                    : 70,
                backgroundColor:
                  location.pathname === '/' ? 'rgba(8, 9, 10, 1)' : undefined,
                height: '100%',
                overflowY: 'scroll',
              }}
            >
              <Container
                fluid
                className={classNames('h-100 d-flex flex-column', {
                  'px-0': location.pathname === '/',
                })}
              >
                <Switch>
                  {!isLeadtopia && !isAmericafirst && (
                    <Route
                      component={generateNullComponent(HomePage)}
                      exact
                      path="/"
                    />
                  )}
                  <Route
                    component={generateNullComponent(LeadsPage)}
                    exact
                    path={isLeadtopia || isAmericafirst ? '/' : '/leads'}
                  />
                  {user?.corpID !== 'leadtopia' && ( // will allow isAmericafirst to do transfers
                    <Route
                      component={generateNullComponent(TransfersPage)}
                      exact
                      path="/agent-transfers"
                    />
                  )}
                  <Route
                    component={generateNullComponent(SupportPage)}
                    exact
                    path="/support"
                  />
                  <Route
                    component={generateNullComponent(MyAgents)}
                    exact
                    path="/myagents"
                  />
                  <Route component={ProfilePage} exact path="/profile/:id" />
                  {user?.flags.hideCampaigns ? null : (
                    <Route
                      component={generateNullComponent(CampaignPage)}
                      exact
                      path="/campaigns"
                    />
                  )}
                  {user?.network.agentDashboards.length > 0 && (
                    <Route
                      component={generateNullComponent(AnalyticsPage)}
                      exact
                      path="/analytics"
                    />
                  )}
                  <Route
                    component={generateNullComponent(ReportsPage)}
                    exact
                    path="/reports"
                  />
                  {Vars.env === 'dev' && (
                    <Route component={VoyagerPage} exact path="/voyager" />
                  )}
                  <Route
                    component={generateNullComponent(CampaignDetails)}
                    exact
                    path="/campaigns/:id"
                  />
                  <Route
                    component={generateNullComponent(VendorsPage)}
                    exact
                    path="/vendors"
                  />
                  <Route
                    component={generateNullComponent(VendorDetails)}
                    exact
                    path="/vendors/:id"
                  />
                  <Route
                    component={generateNullComponent(TextdripAuth)}
                    exact
                    path="/auth"
                  />
                  <Route
                    component={generateNullComponent(GoHighLevelAuth)}
                    exact
                    path="/gh/auth"
                  />
                  {user?.flags.allowBulkSales && (
                    <Route
                      component={generateNullComponent(BulkPurchasePage)}
                      exact
                      path="/bulk-purchase"
                    />
                  )}
                  <Route
                    component={generateNullComponent(LeadsPage)}
                    exact
                    path="/live-transfers"
                  />
                </Switch>
                <Footer />
              </Container>
            </div>
          </LoadingBarProvider>
        </CampaignsNetworkProvider>
      </ErrorBoundary>
    </>
  );
};

const App: React.FunctionComponent = () => (
  <NotificationProvider>
    <AppWrapper />
  </NotificationProvider>
);

export default App;
